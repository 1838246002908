<template>
  <div class="h-full">
    <div class="bg-white h-full relative">
      <div class="absolute top-[8px] right-0 p-2 cursor-pointer z-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 transition duration-100 text-gray-400 hover:text-black"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-test="timeline.closeButton"
          @click="$emit('closeTimeline')"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <!-- Header -->
      <div
        class="h-2 "
        :class="{
          'bg-brand-orange': isEarthquake,
          'bg-deepsea-full text-white': !isEarthquake
        }"
      ></div>
      <div
        data-test="timeline.headerColorBand"
        class="flex justify-between items-center p-6  relative"
      >
        <div class="flex flex-col w-2/3">
          <h3 class="font-bold text-gray-900" data-test="timeline.title">
            Timeline - Policy {{ policyNo.toUpperCase() }}
          </h3>
          <p class="text-[14px] text-gray-500" data-test="timeline.subtitle">
            The policy timeline shows all transactions, payment, document, claim, and notes activity
            in chronological order.
          </p>
        </div>
      </div>
      <div class="px-6 pb-4">
        <label
          for="filter"
          class="block text-xs font-medium text-gray-500"
          data-test="timeline.fitlersTitle"
          >Filters</label
        >
        <div class="relative inline-block">
          <select
            id="filter"
            v-model="currentFilter"
            name="filter"
            class=" block rounded-md !border border-gray-300 py-1 pl-1.5 pr-12 focus:border-sky-500 focus:outline-none !text-xs text-black appearance-none"
            data-test="timeline.fitlersDropdown"
            @change="filterDocs"
          >
            <option value="all" selected data-test="timeline.fitlersAll">All</option>
            <option value="transaction" data-test="timeline.fitlersTransactions">
              Transactions
            </option>
            <option value="payment" data-test="timeline.fitlersPayments">Payments</option>
            <option value="document-upload" data-test="timeline.fitlersDocuments">Documents</option>
            <option value="claim" data-test="timeline.fitlersClaims">Claims</option>
            <option value="policy-note" data-test="timeline.fitlersNotes">Notes</option>
            <option v-if="$internal" value="policy-call-log" data-test="timeline.fitlersNotes">
              Call Logs
            </option>
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            class="w-4 h-4 absolute right-[5px] top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none"
          >
            <path
              fill-rule="evenodd"
              d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div class="bg-white h-full pt-1 pb-[600px] overflow-y-scroll">
        <div v-loading="loading" class="flow-root pl-5 mt-8">
          <div
            v-if="noItemsError"
            class="italic text-gray-500 text-center"
            data-test="timeline.nothingToDisplay"
          >
            Nothing to currently display
          </div>
          <ul role="list" class="">
            <li
              v-for="(activityItem, activityItemIdx) in timelineItems"
              :key="activityItem.createdAt"
              class="group"
            >
              <div class="relative pb-8">
                <span
                  v-if="activityItemIdx !== timelineItems.length - 1"
                  class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                  :data-test="`timeline.item-${activityItemIdx}`"
                  aria-hidden="true"
                />
                <div class="relative flex items-start space-x-3">
                  <template v-if="activityItem.type === 'policy-note'">
                    <div>
                      <div class="relative px-1">
                        <div
                          class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                        >
                          <ChatIcon class="h-5 w-5 text-gray-400" data-test="timeline.chatIcon" />
                        </div>
                      </div>
                    </div>

                    <div class="min-w-0 flex-1 py-1">
                      <div class="text-sm text-gray-500 -mt-3 flex flex-col gap-1">
                        <div class="font-medium text-gray-900" data-test="timeline.noteAttribute">
                          {{ activityItem.createdBy_UserName }} added a policy note
                        </div>
                        <div
                          class="text-gray-600 whitespace-pre-wrap"
                          data-test="timeline.note"
                          v-html="linkifyNote(activityItem.note)"
                        />
                        <div
                          class="whitespace-nowrap flex items-center"
                          data-test="timeline.noteCreatedLine"
                        >
                          <i
                            v-if="activityItem.private === true"
                            class="el-icon-lock"
                            data-test="timeline.noteCreatedAt"
                            style="color:#016ba5"
                          ></i>
                          <div class="mr-2">
                            {{ activityItem.createdAt | shortDateSimpleWithTime }}
                          </div>
                          <div class="space-x-2 ml-2 hidden group-hover:!flex">
                            <button
                              v-if="
                                activityItem.createdBy_AgencyNo === $store.state.auth.data.AgentId
                              "
                              class="text-gray-700 cursor-pointer mr-1 inline-flex space-x-1 items-center hover:!text-gray-900 hover:no-underline"
                              data-test="timeline.editNote"
                              @click="editNote(activityItem)"
                            >
                              <!-- edit icon -->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                class="w-4 h-4 text-gray-500"
                              >
                                <path
                                  d="M13.488 2.513a1.75 1.75 0 0 0-2.475 0L6.75 6.774a2.75 2.75 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.75 2.75 0 0 0 .892-.596l4.261-4.262a1.75 1.75 0 0 0 0-2.474Z"
                                />
                                <path
                                  d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9A.75.75 0 0 1 14 9v2.25A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2H7a.75.75 0 0 1 0 1.5H4.75Z"
                                />
                              </svg>
                              <!-- /edit icon -->
                              <span>Edit</span>
                            </button>

                            <button
                              v-if="
                                activityItem.createdBy_AgencyNo ===
                                  $store.state.auth.data.AgentId && $internal
                              "
                              class="text-red-500 cursor-pointer hover:no-underline hover:text-red-900 inline-flex items-center"
                              data-test="timeline.deleteNote"
                              @click="tryDeleteNote(activityItem)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                class="w-4 h-4"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z"
                                  clip-rule="evenodd"
                                />
                              </svg>

                              <span>Delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="activityItem.type === 'policy-call-log'">
                    <div>
                      <div class="relative px-1">
                        <div
                          class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                        >
                          <CallIcon class="h-5 w-5 text-gray-400" data-test="timeline.callIcon" />
                        </div>
                      </div>
                    </div>

                    <div class="min-w-0 flex-1 py-1">
                      <div class="text-sm text-gray-500 -mt-3 flex flex-col gap-1">
                        <div class="font-medium text-gray-900" data-test="timeline.noteAttribute">
                          {{ activityItem.createdBy_UserName }} added a call
                        </div>
                        <div class="flex items-center gap-1">
                          <div class="h-3.5 w-3.5" style="color:#016ba5">
                            <svg
                              v-if="activityItem.IsInboundCall"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                              />
                            </svg>
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                              />
                            </svg>
                          </div>
                          {{ activityItem.IsInboundCall ? "Inbound" : "Outbound" }} ⸱
                          {{ activityItem.CallerName }} ⸱
                          {{ displayPhoneNumber(activityItem.CallerPhone) }} ⸱
                          {{ callerTypeInfo(activityItem) }}
                        </div>
                        <div
                          class="text-gray-600 whitespace-pre-wrap"
                          data-test="timeline.note"
                          v-html="linkifyNote(activityItem.note)"
                        ></div>
                        <div class="flex gap-1 flex-wrap">
                          <span
                            v-for="tag in tagsForNote(activityItem)"
                            :key="tag"
                            class="bg-blue-200 text-blue-800 px-1.5 py-1 text-xs rounded-full text-center whitespace-nowrap"
                          >
                            {{ tag }}
                          </span>
                        </div>
                        {{ " " }}
                        <div
                          class="whitespace-nowrap flex items-center"
                          data-test="timeline.noteCreatedLine"
                        >
                          <i
                            v-if="activityItem.private === true"
                            class="el-icon-lock"
                            data-test="timeline.noteCreatedAt"
                            style="color:#016ba5"
                          ></i>
                          <div class="mr-2">
                            {{ activityItem.createdAt | shortDateSimpleWithTime }}
                          </div>

                          <div class="space-x-2 ml-2 hidden group-hover:!flex">
                            <button
                              v-if="
                                activityItem.createdBy_AgencyNo === $store.state.auth.data.AgentId
                              "
                              class="text-gray-700 cursor-pointer mr-1 inline-flex space-x-1 items-center hover:!text-gray-900 hover:no-underline"
                              data-test="timeline.editNote"
                              @click="editNote(activityItem)"
                            >
                              <!-- edit icon -->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                class="w-4 h-4 text-gray-500"
                              >
                                <path
                                  d="M13.488 2.513a1.75 1.75 0 0 0-2.475 0L6.75 6.774a2.75 2.75 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.75 2.75 0 0 0 .892-.596l4.261-4.262a1.75 1.75 0 0 0 0-2.474Z"
                                />
                                <path
                                  d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9A.75.75 0 0 1 14 9v2.25A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2H7a.75.75 0 0 1 0 1.5H4.75Z"
                                />
                              </svg>
                              <!-- /edit icon -->
                              <span>Edit</span>
                            </button>

                            <button
                              v-if="
                                activityItem.createdBy_AgencyNo ===
                                  $store.state.auth.data.AgentId && $internal
                              "
                              class="text-red-500 cursor-pointer hover:no-underline hover:text-red-900 inline-flex items-center"
                              data-test="timeline.deleteNote"
                              @click="tryDeleteNote(activityItem)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                class="w-4 h-4"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z"
                                  clip-rule="evenodd"
                                />
                              </svg>

                              <span>Delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="activityItem.type === 'transaction'">
                    <div>
                      <div class="relative px-1">
                        <div
                          class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                        >
                          <BellIcon class="h-5 w-5 text-gray-400" data-test="timeline.bellIcon" />
                        </div>
                      </div>
                    </div>
                    <div class="min-w-0 flex-1 py-1">
                      <div class="text-sm text-gray-500 -mt-3">
                        <p
                          v-if="activityItem.transactionType === 'EB'"
                          class="font-medium text-gray-900"
                          data-test="timeline.endorsementAttribute"
                        >
                          {{ activityItem.userName }} created an endorsement -
                          {{ activityItem.transactionReason }}
                        </p>
                        <p
                          v-else
                          class="font-medium text-gray-900"
                          data-test="timeline.endorsementReason"
                        >
                          {{ activityItem.userName }} - {{ activityItem.transactionReason }}
                        </p>
                        {{ " " }}
                        <p v-if="['EB', 'EBO', 'EBM'].includes(activityItem.transactionType)">
                          <span class="!text-gray-700" data-test="timeline.endorsementNote"
                            >{{
                              activityItem.transactionNotes ? activityItem.transactionNotes : ""
                            }}
                          </span>
                          <br v-if="activityItem.transactionNotes" />
                          <span
                            v-if="activityItem.coverageADelta"
                            data-test="timeline.endorsementCoverageADelta"
                            >Coverage A: {{ activityItem.coverageADelta > 0 ? "+" : "" }}
                            {{ activityItem.coverageADelta | timelineCurrency }} <br
                          /></span>

                          <span
                            v-if="activityItem.coverageBDelta"
                            data-test="timeline.endorsementCoverageBDelta"
                            >Coverage B: {{ activityItem.coverageBDelta > 0 ? "+" : "" }}
                            {{ activityItem.coverageBDelta | timelineCurrency }}<br
                          /></span>

                          <span
                            v-if="activityItem.coverageDDelta"
                            data-test="timeline.endorsementCoverageDDelta"
                            >Coverage D: {{ activityItem.coverageDDelta > 0 ? "+" : "" }}
                            {{ activityItem.coverageDDelta | timelineCurrency }}<br
                          /></span>

                          <span
                            v-if="activityItem.coverageEDelta"
                            data-test="timeline.endorsementCoverageEDelta"
                            >Coverage E: {{ activityItem.coverageEDelta === 1 ? "Added" : "Removed"
                            }}<br
                          /></span>

                          <span
                            v-if="activityItem.coverageFDelta"
                            data-test="timeline.endorsementCoverageFDelta"
                            >Coverage F: {{ activityItem.coverageFDelta > 0 ? "+" : "" }}
                            {{ activityItem.coverageFDelta | timelineCurrency }}<br
                          /></span>

                          <span
                            v-if="activityItem.coverageGDelta"
                            data-test="timeline.endorsementCoverageGDelta"
                            >Coverage G: {{ activityItem.coverageGDelta > 0 ? "+" : ""
                            }}{{ activityItem.coverageGDelta | timelineCurrency }}<br
                          /></span>

                          <span
                            v-if="activityItem.coverageHDelta"
                            data-test="timeline.endorsementCoverageHDelta"
                            >Coverage H: {{ activityItem.coverageHDelta > 0 ? "+" : "" }}
                            {{ activityItem.coverageHDelta | timelineCurrency }}<br
                          /></span>

                          <span v-if="activityItem.coverageIDelta"
                            >Coverage I: {{ activityItem.coverageIDelta > 0 ? "+" : "" }}
                            {{ activityItem.coverageIDelta | timelineCurrency }} <br
                          /></span>

                          <span
                            v-if="activityItem.coverageJDelta"
                            data-test="timeline.endorsementCoverageJDelta"
                            >Coverage J: {{ activityItem.coverageJDelta > 0 ? "+" : "" }}
                            {{ activityItem.coverageJDelta | timelineCurrency }}<br
                          /></span>

                          <span
                            v-if="activityItem.coverageKDelta"
                            data-test="timeline.endorsementCoverageKDelta"
                            >Coverage K: {{ activityItem.coverageKDelta === 1 ? "Added" : "Removed"
                            }}<br
                          /></span>
                          <span
                            v-if="activityItem.coverageLDelta"
                            data-test="timeline.endorsementCoverageLDelta"
                            >Coverage L: {{ activityItem.coverageLDelta === 1 ? "Added" : "Removed"
                            }}<br
                          /></span>
                          <span
                            v-if="activityItem.deductibleDelta"
                            data-test="timeline.endorsementDeductibleDelta"
                            >Deductible: {{ activityItem.deductibleDelta > 0 ? "+" : "" }}
                            {{ activityItem.deductibleDelta | timelineCurrency }}</span
                          >
                        </p>
                        {{ " " }}
                        <!-- <a :href="activityItem.assigned.href" class="font-medium text-gray-900">{{
                        activityItem.policyNumber
                      }}</a> -->
                        {{ " " }}
                        <div class="whitespace-nowrap" data-test="timeline.endorsementCreatedAt">
                          {{ activityItem.createdAt | shortDateSimpleWithTime }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="activityItem.type === 'claim'">
                    <div>
                      <div class="relative px-1">
                        <div
                          class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                        >
                          <ShieldExclamationIcon
                            class="h-5 w-5 text-red-500"
                            data-test="timeline.shieldIcon"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="min-w-0 flex-1 py-1.5 -mt-3">
                      <div class="text-sm text-gray-500">
                        <p class="font-medium text-gray-900" data-test="timeline.claimAttribute">
                          Claim ID {{ activityItem.claimNumber }} created
                        </p>

                        <div class="whitespace-nowrap" data-test="timeline.claimCreatedAt">
                          {{ format(parseISO(activityItem.noticeDate), "MM/dd/yyyy p") }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="activityItem.type === 'payment'">
                    <div>
                      <div class="relative px-1">
                        <div
                          class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                        >
                          <CurrencyDollarIcon
                            class="h-5 w-5 text-gray-400"
                            data-test="timeline.dollarIcon"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="min-w-0 flex-1 py-1.5 -mt-3">
                      <div
                        v-if="activityItem.recordType == 'Refund'"
                        class="text-sm text-gray-500"
                        data-test="timeline.refundRecord"
                      >
                        {{ " " }}
                        <p class="font-medium text-gray-900" data-test="timeline.refundAttribute">
                          {{ activityItem.createdByUserName }}
                          processed <span v-html="linkifyNote(activityItem.description)"></span> for
                          {{ activityItem.amount | timelineCurrencyAbsolute }}
                        </p>
                        {{ " " }}
                        <div
                          class="whitespace-nowrap text-gray-500"
                          data-test="timeline.refundCreatedAt"
                        >
                          {{ activityItem.createdAt | shortDateSimpleWithTime }}
                        </div>
                      </div>
                      <div
                        v-else-if="activityItem.recordType == 'Transfer'"
                        class="text-sm text-gray-500"
                        data-test="timeline.transferRecord"
                      >
                        {{ " " }}
                        <p class="font-medium text-gray-900" data-test="timeline.transferAttribute">
                          {{ activityItem.createdByUserName }}
                          made a <span v-html="linkifyNote(activityItem.description)"></span> of
                          {{ activityItem.amount | timelineCurrencyAbsolute }}
                        </p>
                        {{ " " }}
                        <div
                          class="whitespace-nowrap text-gray-500"
                          data-test="timeline.transferCreatedAt"
                        >
                          {{ activityItem.createdAt | shortDateSimpleWithTime }}
                        </div>
                      </div>
                      <div v-else class="text-sm text-gray-500">
                        <!-- <a :href="activityItem.person.href" class="font-medium text-gray-900">{{
                        activityItem.person.name
                      }}</a> -->
                        {{ " " }}
                        <p class="font-medium text-gray-900" data-test="timeline.paymentAttribute">
                          {{
                            activityItem.payment.paymentTypeId === 3 && activityItem.amount > 0
                              ? activityItem.payment.payeeName
                              : activityItem.createdByUserName
                          }}
                          paid {{ activityItem.amount | timelineCurrency }} via
                          {{ activityItem.payment.paymentType.paymentTypeDescription }}
                        </p>
                        {{ " " }}
                        <div
                          class="whitespace-nowrap text-gray-500"
                          data-test="timeline.paymentCreatedAt"
                        >
                          {{ activityItem.createdAt | shortDateSimpleWithTime }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="activityItem.type === 'document-upload'">
                    <div>
                      <div class="relative px-1">
                        <div
                          class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white"
                        >
                          <DocumentAddIcon
                            class="h-5 w-5 text-gray-400"
                            data-test="timeline.documentIcon"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="min-w-0 flex-1 py-0 -mt-3">
                      <div
                        v-if="activityItem.versions[0].createdByUserName"
                        class="text-sm leading-8 text-gray-500"
                      >
                        <span>
                          <span
                            class="mr-0.5 font-medium text-gray-900"
                            data-test="timeline.documentUploadedName"
                          >
                            {{ activityItem.versions[0].createdByUserName }}
                            -
                            <a
                              :key="activityItem.docName"
                              class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm cursor-pointer"
                              @click="openDocument(activityItem)"
                            >
                              <span class="absolute flex flex-shrink-0 items-center justify-center">
                                <span
                                  class="bg-blue-400 h-1.5 w-1.5 rounded-full"
                                  aria-hidden="true"
                                />
                              </span>
                              <span
                                class="ml-3.5 font-medium text-gray-900"
                                data-test="timeline.documentName"
                                >{{ activityItem.docName }}</span
                              >
                            </a>
                            - {{ activityItem.typeName }}</span
                          >
                          {{ " " }}
                        </span>
                        <div class="whitespace-nowrap" data-test="timeline.documentCreatedAt">
                          {{ activityItem.createdAt | shortDateSimpleWithTime }}
                        </div>
                      </div>
                      <div v-else class="text-sm leading-8 text-gray-500">
                        <span>
                          <span
                            class="mr-0.5 font-medium text-gray-900"
                            data-test="timeline.documentUploadedName"
                          >
                            <a
                              :key="activityItem.docName"
                              class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm cursor-pointer"
                              @click="openDocument(activityItem)"
                            >
                              <span class="absolute flex flex-shrink-0 items-center justify-center">
                                <span
                                  class="bg-blue-400 h-1.5 w-1.5 rounded-full"
                                  aria-hidden="true"
                                />
                              </span>
                              <span
                                class="ml-3.5 font-medium text-gray-900"
                                data-test="timeline.documentName"
                                >{{ activityItem.docName }}</span
                              >
                            </a>
                            was uploaded as {{ activityItem.typeName }}</span
                          >
                          {{ " " }}
                        </span>
                        <div class="whitespace-nowrap" data-test="timeline.documentCreatedAt">
                          {{ activityItem.createdAt | shortDateSimpleWithTime }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Notes
      class="border-t border-gray-200 absolute bottom-0 w-full px-4 bg-white pb-4"
      :notes="notes"
      :policy-no="policyNo"
      :editing="editing"
      :note-to-edit="noteToEdit"
      @refresh="buildData()"
      @cancelNote="unsetEdit()"
    />
    <TransitionModal>
      <div v-if="deleteNoteModal.show">
        <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75"
            @click="deleteNoteModal.show = false"
          ></div>
          <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
              viewBox="0 0 20 20"
              fill="currentColor"
              @click="deleteNoteModal.show = false"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <div>Are you sure you want to delete this policy note?</div>
            <div class="flex justify-end">
              <button
                width="150px"
                data-test="addNoteButton"
                class="text-white py-3 px-4 rounded-md hover:bg-opacity-80"
                :class="{
                  'bg-deepsea-full': !$isEarthquake,
                  'bg-brand-orange': $isEarthquake
                }"
                :disabled="loading"
                @click="deleteNote(deleteNoteModal.note)"
              >
                <span v-if="!loading">Confirm</span>
                <svg
                  v-else
                  class="animate-spin h-5 w-5 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </TransitionModal>
  </div>
</template>

<script>
import BellIcon from "@/components/icons/BellIcon";
import ChatIcon from "@/components/icons/ChatIcon";
import CallIcon from "@/components/icons/CallIcon";
import CurrencyDollarIcon from "@/components/icons/CurrencyDollarIcon";
import DocumentAddIcon from "@/components/icons/DocumentAddIcon";
import ShieldExclamationIcon from "@/components/icons/ShieldExclamationIcon";
import { Constants } from "@/config/index";
import numeral from "numeraljs";
import { DateFiltersMixin } from "../policy-wizard/utils/mixins/formatting";
import TransitionModal from "@/components/transitions/TransitionModal.vue";
import { format, parseISO } from "date-fns";

// import dayjs from "dayjs";
import Notes from "../policy/notes/Notes.vue";
import { mapActions } from "vuex";
import docs from "@/utils/documents/docTypes";
export default {
  components: {
    ChatIcon,
    DocumentAddIcon,
    CurrencyDollarIcon,
    BellIcon,
    ShieldExclamationIcon,
    CallIcon,
    Notes,
    TransitionModal
  },
  filters: {
    timelineCurrency(value) {
      if (value.toString().includes(".")) {
        return numeral(value).format("$0,000.00");
      } else {
        return numeral(value).format("$0,000");
      }
    },
    timelineCurrencyAbsolute(value) {
      if (value.toString().includes(".")) {
        return numeral(Math.abs(value)).format("$0,000.00");
      } else {
        return numeral(Math.abs(value)).format("$0,000");
      }
    }
  },
  mixins: [DateFiltersMixin],

  props: {
    isEarthquake: Boolean,
    isCommercial: Boolean,
    notes: Array,
    policyNo: String
  },
  data() {
    return {
      format,
      parseISO,
      timelineItems: [],
      copy: [],
      editing: false,
      noteToEdit: null,
      noItemsError: false,
      loading: false,
      claims: [],
      currentFilter: "all",
      deleteNoteModal: {
        show: false,
        note: {}
      }
    };
  },
  computed: {
    computedDocTypesArray() {
      if (this.$store?.state?.policy?.confirmationData?.state === "NY") return docs.nyDocTypesArray;
      else return docs.docTypesArray;
    }
  },
  async mounted() {
    this.claims = await this.getAllClaims(this.policyNo);
    this.buildData();
  },

  methods: {
    tryDeleteNote(note) {
      this.deleteNoteModal.show = true;
      this.deleteNoteModal.note = note;
    },
    displayPhoneNumber(phone) {
      const parts = phone.split("-");
      return `(${parts[0]}) ${parts[1]}-${parts[2]}`;
    },
    callerTypeInfo(activityItem) {
      if (activityItem.CallerType === "insured") return "Insured";
      if (activityItem.CallerType === "agent")
        if (activityItem.OrganizationName) return `Agent - ${activityItem.OrganizationName}`;
        else return "Agent";
      if (activityItem.CallerType === "lender")
        if (activityItem.OrganizationName) return `Lender - ${activityItem.OrganizationName}`;
        else return "Lender";
    },
    tagsForNote(note) {
      return note.reason.split(", ");
    },
    ...mapActions("policy", {
      getPolicyNotes: "getPolicyNotes",
      deletePolicyNote: "deletePolicyNote"
    }),
    ...mapActions("claims", ["getAllClaims"]),
    linkifyNote(value) {
      if (!value) return value;
      const re = /([A-Za-z]{3}3\d{6})/g;
      const routeName = this.$router.currentRoute.name;
      return value.replace(re, (val) => {
        const params = { name: routeName, params: { policyNo: val.toUpperCase() } };
        const resolved = this.$router.resolve(params);
        const href = resolved.href;
        const anchor = `<a class="notes-link" target="_blank" href="/${href}">${val.toUpperCase()}</a>`;
        return anchor;
      });
    },
    openDocument(item) {
      const path = Constants.GET_PDF_URL + "?link=" + item.versions[item.versions.length - 1].link;
      window.open(path, "_blank");
    },
    filterDocs() {
      this.unsetEdit();
      this.timelineItems = this.copy;
      if (this.currentFilter !== "all") {
        this.timelineItems = this.timelineItems.filter((x) => x.type === this.currentFilter);
      }
      if (this.timelineItems.length === 0) {
        this.noItemsError = true;
      } else this.noItemsError = false;
    },
    async deleteNote(item) {
      try {
        this.loading = true;
        delete item.type;
        await this.deletePolicyNote({ policyNo: this.policyNo, note: item });
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
        this.deleteNoteModal.show = false;
        this.buildData();
      }
    },
    unsetEdit() {
      this.editing = false;
      this.noteToEdit = null;
    },
    editNote(item) {
      this.editing = true;
      this.noteToEdit = item;
    },
    async buildData() {
      try {
        this.loading = true;
        const transactions = this.$store.state.policy.transactionSummary.transactions ?? [];
        if (transactions.length) {
          transactions.forEach((x) => {
            x.type = "transaction";
          });
        }

        const documents = this.$store.state.document.pdfs ?? [];
        if (documents.length) {
          documents.forEach((x) => {
            x.type = "document-upload";
            const docTypeObj = this.computedDocTypesArray.find(
              (docType) => docType.id === x.docTypeId
            );
            x.typeName = !docTypeObj.type || docTypeObj.type === undefined ? "" : docTypeObj.type;
          });
        }

        // claims that we fetch on mount - this is so that we can utilize this method for notes updating without calling claims again
        if (this.claims.length) {
          this.claims.forEach((x) => {
            x.type = "claim";
          });
        }

        const payments = this.$store.state.policy.confirmationData.payments;
        if (payments.length) {
          payments.forEach((x) => {
            x.type = "payment";
          });
        }

        const notes = await this.getPolicyNotes(this.policyNo);

        if (notes.length) {
          notes.forEach((x) => {
            // normalize notes created_At to createdAt like everything else
            x.createdAt = x.created_At;
            if (x.type === "callLog") {
              x.type = "policy-call-log";
              const meta = JSON.parse(x.meta);
              x.IsInboundCall = meta.IsInboundCall;
              x.OrganizationName = meta.OrganizationName;
              x.CallerName = meta.CallerName;
              x.CallerPhone = meta.CallerPhone;
              x.CallerType = meta.CallerType;
            } else x.type = "policy-note";
          });
        }

        const userDocs = documents.filter((x) => x.versions[0].isUpload !== false);
        this.timelineItems = [...transactions, ...notes, ...userDocs, ...this.claims, ...payments];

        this.timelineItems.sort(
          (a, b) => new Date(b.createdAt ?? b.noticeDate) - new Date(a.createdAt ?? a.noticeDate)
        );
        this.copy = this.timelineItems;
        // stay on current filter
        this.filterDocs(this.currentFilter);
        this.unsetEdit();
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style>
.notes-link {
  color: #004c9d !important;
  &:hover {
    text-decoration: underline;
  }
}
</style>
