import cloneDeep from "lodash/cloneDeep";
import { wktToPolygon } from "./utils";

/** @type {import("vuex").GetterTree<neptune.IPolicyState, neptune.IRootState>} */
const getters = {
  policyData(_state, _getters, rootState) {
    return rootState.auth.validateReturn.policy;
  },
  isSigned(_state, _getters, rootState, rootGetters) {
    if (!rootState.auth?.validateReturn) {
      return false;
    }
    const documentsWithSignatures = rootGetters["document/documentsWithSignatures"];

    if (documentsWithSignatures.length === 0) return false;

    return documentsWithSignatures.every((d) => {
      if (d.signatures?.length === 0) return true;
      return d.signatures.every((s) => {
        return s.signedByName != null;
      });
    });
  },
  isAgentSignatureRequired({ confirmationData }) {
    if (
      !confirmationData?.isSigned ||
      !Array.isArray(confirmationData.isSigned) ||
      !confirmationData.isSigned.length
    ) {
      return false;
    }

    const [agentSignature] = confirmationData.isSigned;
    const { requiresSignatures } = agentSignature;
    return requiresSignatures;
  },

  isInsuredSignatureRequired({ confirmationData }) {
    if (
      !confirmationData?.isSigned ||
      !Array.isArray(confirmationData.isSigned) ||
      !confirmationData.isSigned.length
    ) {
      return false;
    }

    const [, insuredSignature] = confirmationData.isSigned;
    const { requiresSignatures } = insuredSignature;
    return requiresSignatures;
  },

  hasAgentSigned(state) {
    if (
      !state.confirmationData?.isSigned ||
      !Array.isArray(state.confirmationData.isSigned) ||
      !state.confirmationData.isSigned.length
    ) {
      return false;
    }

    const [agentSignature] = state.confirmationData.isSigned;
    const { isSigned } = agentSignature;
    return isSigned;
  },

  hasInsuredSigned({ confirmationData }) {
    if (
      !confirmationData?.isSigned ||
      !Array.isArray(confirmationData.isSigned) ||
      !confirmationData.isSigned.length
    ) {
      return false;
    }

    const [, insuredSignature] = confirmationData.isSigned;
    const { isSigned } = insuredSignature;
    return isSigned;
  },

  hasUserSigned(_x1, { hasAgentSigned, hasInsuredSigned }, _x2, rootGetters) {
    const party = rootGetters["userdata/party"];
    if (party === 1) {
      return hasAgentSigned;
    }
    return hasInsuredSigned;
  },

  isUserSignatureRequired(
    _x1,
    { isAgentSignatureRequired, isInsuredSignatureRequired },
    _x2,
    rootGetters
  ) {
    const party = rootGetters["userdata/party"];
    if (party === 1) {
      return isAgentSignatureRequired;
    }
    return isInsuredSignatureRequired;
  },
  productNo({ transactions }) {
    // this is typically not ideal since transactions can change overtime;
    // However, productId doesn't change between transactions.
    const [firstTransaction] = transactions;
    return firstTransaction?.productId;
  },
  isPaid(_state, _getters, rootState, rootGetters) {
    /** @type {neptune.getters.IRootGetters} */
    const { "policy/allData": allData } = rootGetters;

    const { isPaid } = allData;

    if (isPaid) {
      return isPaid;
    }

    return false;
  },

  /** @returns {neptune.Transaction} */
  summaryData(state) {
    const trans = state.transactions[0];
    return (
      (trans || {
        policyTransactionId: null,
        policyId: null,
        policyIsActive: null,
        baId: null,
        postDate: null,
        accountingDate: null,
        effectiveDate: null,
        inceptionDate: null,
        originalPostDate: null,
        originalTransType: null,
        transactionNotes: null,
        transactionReason: null,
        transactionType: null,
        userId: null,
        agencyId: null,
        agencyNo: null,
        addr1: null,
        addr2: null,
        city: null,
        state: null,
        zip: null,
        county: null,
        countyFips: null,
        firstName: null,
        lastName: null,
        coApplicantFirstName: null,
        coApplicantLastName: null,
        coApplicantPhone: null,
        coApplicantEmail: null,
        policyHolderId: null,
        coApplicantId: null,
        email: null,
        mailingAddr1: null,
        mailingAddr2: null,
        mailingCountry: null,
        mailingCity: null,
        mailingState: null,
        mailingZip: null,
        billInitially: null,
        billOnRenewal: null,
        deliveryMethod: null,
        paymentMethod: null,
        phone: null,
        floodZone: null,
        condoFloor: null,
        constructionType: null,
        constructionDesc: null,
        ecElevation: null,
        foundationType: null,
        foundationTypeDesc: null,
        occupancyType: null,
        occupancyTypeDesc: null,
        propertyType: null,
        propertyTypeDesc: null,
        steps: null,
        stories: null,
        yearBuilt: null,
        priorClaims: null,
        tiv: null,
        elevationDiff: null,
        coverageALimit: null,
        coverageBLimit: null,
        coverageDLimit: null,
        coverageELimit: null,
        coverageFLimit: null,
        coverageGLimit: null,
        coverageHLimit: null,
        coverageILimit: null,
        deductible: null,
        coverageAPremium: null,
        coverageBPremium: null,
        coverageDPremium: null,
        coverageEPremium: null,
        coverageFPremium: null,
        coverageGPremium: null,
        coverageHPremium: null,
        coverageIPremium: null,
        deductibleAdjustment: null,
        grossPremium: null,
        policyFee: null,
        subtotalTaxes: null,
        totalPremium: null,
        tnxGrossPremium: null,
        tnxTotalPremium: null,
        emergencyFundSurcharge: null,
        firemanTax: null,
        maintenanceAssessment: null,
        municipalTax: null,
        mwuaPolicyFee: null,
        mwuaPolicyPremium: null,
        stampingFeesPolicyPremium: null,
        stampingFeesPolicyFee: null,
        surplusTaxPolicyFee: null,
        surplusTaxPolicyPremium: null,
        transactionFeePolicyFee: null,
        transactionFeePolicyPremium: null,
        productId: null,
        useEmbededInsuredSignature: null,
        isPolicySigned: null,
        isPolicyPaid: null,
        meta: null,
        namedStormExclusion: null,
        createdAt: null
      })
    );
  },

  allData(state, _getters, rootState) {
    const transactions = [...(rootState.quoting.transactions || [])] || [];
    const last = transactions.pop() || {};
    return {
      ...(last || []),
      ...(state.confirmationData || []),
      ...(state.policyData || []),
      ...(state.invoiceInfo || []),
      ...(state.lienholderInfo || [])
    };
  },

  aggregatedTransactionValues(_state, _getters, rootState) {
    /** @type {neptune.IRootState} */
    const {
      quoting: { transactions }
    } = rootState;

    if (!transactions?.length) return;

    const sorted = cloneDeep(transactions || []).sort(
      (a, b) => Date.parse(a.postDate) - Date.parse(b.postDate)
    );

    const latest = sorted[sorted.length - 1];

    const keys = [
      "constructionType",
      "coverageALimit",
      "coverageAPremium",
      "coverageBLimit",
      "coverageBPremium",
      "coverageDLimit",
      "coverageDPremium",
      "coverageELimit",
      "coverageEPremium",
      "coverageFLimit",
      "coverageFPremium",
      "coverageGLimit",
      "coverageGPremium",
      "coverageHLimit",
      "coverageHPremium",
      // 'coverageILimit',
      "coverageIPremium",
      "deductible",
      "deductibleAdjustment",
      "firemanTax",
      "grossPremium",
      "maintenanceAssessment",
      "municipalTax",
      "mwuaPolicyFee",
      "mwuaPolicyPremium",
      "policyFee",
      "stampingFeesPolicyFee",
      "stampingFeesPolicyPremium",
      "subtotalTaxes",
      "surplusTaxPolicyFee",
      "surplusTaxPolicyPremium",
      // 'taxes',
      // 'tiv',
      "tnxGrossPremium",
      "tnxTotalPremium",
      // 'totalDue',
      // 'totalInvoiceAmount',
      // 'totalPayments',
      "totalPremium",
      // 'transactionFee',
      "transactionFeePolicyFee",
      "transactionFeePolicyPremium"
      // 'userId',
      // 'yearBuilt',
    ];

    return sorted.reduce(
      (prev, cur) => {
        keys.forEach((key) => {
          if (!prev[key]) {
            prev[key] = 0;
          }

          prev[key] += +cur[key];
        });

        return prev;
      },
      {
        coverageILimit: latest.coverageILimit === 1 ? latest.coverageILimit : "Up to $25,000"
      }
    );
  },

  /**
   * @returns {neptune.CancelReason}
   */
  cancelReason(state, getters) {
    const code = getters.allData.reasonCode;
    if (!getters.allData.cancelReasons?.length) return;

    if (code && !!getters.allData.cancelReasons) {
      const match = getters.allData.cancelReasons.find((v) => v.reasonCode === code);

      return match;
    }
  },

  defaultPaymentMethod(state) {
    return state.defaultPaymentMethod;
  },
  addressDataVersion(state) {
    if (!state.transactionSummary?.meta) {
      return undefined;
    }

    let addressDataVersion;
    try {
      const meta = JSON.parse(state.transactionSummary.meta);
      addressDataVersion = meta?.addressDataVersion;
    } catch (e) {
      console.error(e);
      return undefined;
    }

    return addressDataVersion;
  },
  addressID(state) {
    if (!state.transactionSummary?.meta) {
      return undefined;
    }

    let addressID;
    try {
      const meta = JSON.parse(state.transactionSummary.meta);
      addressID = meta?.addressID;
    } catch (e) {
      console.error(e);
      return undefined;
    }

    return addressID;
  },
  /**
   * @typedef {Object} LatLng
   * @property {number} lat - The latitude value.
   * @property {number} lng - The longitude value.
   * @returns {LatLng}
   */
  coordinates(state) {
    return {
      lat: state.confirmationData?.latitude,
      lng: state.confirmationData?.longitude
    };
  },
    /**
   * @returns {LatLng[]}
   */
  polygon(state) {
    if (!state.addressData?.polygon) {
      return null;
    }

    return wktToPolygon(state.addressData.polygon);
  },
  isAddressDataLoading(state) {
    return Boolean(state.addressData?.loading);
  }
};

export default getters;
