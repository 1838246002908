/* eslint-disable eqeqeq */
<template>
  <div v-loading="loading" class="map-display">
    <div v-if="hasCoordinates" class="map-display__inner">
      <gmap-map
        ref="gmap"
        data-test="manualAdd.map"
        :center="mapCenter"
        :zoom="zoomLevel"
        :tilt="0"
        map-type-id="hybrid"
        :options="{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: true
        }"
        style="width: 100%; height: 450px;"
      >
        <gmap-marker
          v-if="!polygon || !areCoordinatesInsidePolygon"
          data-test="manualAdd.pin"
          pre="addressMark"
          :position="coordinates"
          :draggable="false"
        />
        <gmap-polygon
          v-if="polygon && areCoordinatesInsidePolygon"
          :draggable="false"
          :editable="false"
          :path="polygon"
          :options="{
            fillColor: '#28bdbd',
            strokeColor: '#28bdbd'
          }"
        ></gmap-polygon>
      </gmap-map>
    </div>
    <div
      v-if="!loading && !hasCoordinates"
      class="text-center bg-white flex flex-col justify-center items-center absolute top-0 bottom-0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">Could not display map</h3>
      <p class="mt-1 text-sm text-gray-500">
        We were unable to retrieve the coordinates for this location
      </p>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    address: {
      type: String,
      required: true
    },
    coordinates: {
      type: Object,
      default: () => ({})
    },
    polygon: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 20,
      validator: (val) => val <= 20 && val >= 2
    }
  },
  data() {
    return {
      zoomLevel: 19,
      centerMap: { lat: 27, lng: -82 },
      markers: [],
      googleAddress: null,
      gcodePlace: null,
      currentLatLng: null
    };
  },
  computed: {
    google: gmapApi,
    hasCoordinates() {
      return this.coordinates.lat && this.coordinates.lng;
    },
    mapCenter() {
      if (!this.polygon || !this.google?.maps?.LatLngBounds) {
        return this.coordinates;
      }

      const bounds = new this.google.maps.LatLngBounds();
      this.polygon.forEach((point) => {
        bounds.extend(point);
      });

      return bounds.getCenter();
    },
    areCoordinatesInsidePolygon() {
      if (!this.polygon?.length || !this.google?.maps) {
        return false;
      }

      const latLng = new this.google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);
      const polygon = new this.google.maps.Polygon({
        path: this.polygon
      });

      return this.google.maps.geometry.poly.containsLocation(latLng, polygon);
    }
  }
};
</script>

<style lang="scss" scoped>
.map-display {
  position: relative;
  background-color: #ccc;
  min-width: 100px;
  height: 0;
  padding-bottom: 56.25%;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
