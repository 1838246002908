import { assign } from "xstate";

import { Constants } from "@/config";
import router from "@/router";
import store from "@/store";

export const clearError = assign({ error: null });

export const setACHResponse = assign((context, event) => ({
  ach_response: event.data
}));

export const setError = assign((context, event) => ({
  error: event.data
}));

export const setPaymentError = assign((context, event) => {
  let message = event.data.message;

  if (event.data.setup_intent?.status === "requires_payment_method") {
    message =
      "This payment method has failed validation, please check the account and routing number provided.";
  }

  return {
    paymentError: {
      message,
      error: event.data
    }
  };
});

export const clearPaymentError = assign(() => ({
  paymentError: null
}));

export const setProcessPaymentError = assign((context, event) => {
  return {
    error: {
      message:
        event.data?.response.data?.error?.message ??
        "There was an issue processing your payment. Please contact support"
    }
  };
});

export const setConfirmation = assign((context, event) => ({
  confirmation: event.data
}));

export const setDocuments = assign((context, event) => ({
  documents: event.data
}));

export const setOptInAutoPayRenewal = assign((context, event, meta) => {
  return {
    optInAutoPayRenewal:
      (typeof event.data !== "object" && Boolean(event.data)) || Boolean(meta.action.data)
  };
});

export const setPaymentMethods = assign((context, event) => ({
  payment_methods: event.data
}));

export const setSignatures = assign((context, event) => ({
  signatures: event.data
}));

export const setTransactions = assign((context, event) => ({
  transactions: event.data
}));

export const setStripe = assign((context, event) => ({
  stripe: event.data
}));

export const setClientSecret = assign((context, event) => {
  return {
    client_secret: event.data.client_secret
  };
});

export const setPaymentMethod = assign((_, event) => {
  return {
    paymentMethod: event.data
  };
});

export const setPaymentMethodFromSetupIntent = assign((_, event) => {
  return {
    paymentMethod: {
      id: event.data.payment_method
    }
  };
});

export const setTransactionFees = assign((_, event) => {
  return {
    transaction_fees: event.data
  };
});

export const setTransactionFeesFromPayload = assign((_, event) => {
  return {
    transaction_fees: {
      FeeYear: event.data?.payload?.feeYear,
      FeeMonthly: event.data?.payload?.feeMonthly,
      AmountYear: event.data?.payload?.amountYear,
      AmountMonthly: event.data?.payload?.amountMonthly
    }
  };
});

export const clearTransactionFees = assign(() => {
  return {
    transaction_fees: null,
    ackFee: false
  };
});

export const setAckFee = assign((context, event) => {
  return {
    ackFee: Boolean(event.data)
  };
});

export function logout() {
  store.commit("auth/CLEAR_AUTH");
}

export function redirectInsured() {
  router.push({ name: "generatePolicyEmail" });
}

export function redirectAgent() {
  window.location.assign(Constants.AGENT_LOGIN);
}
